import React, { useState, useEffect } from "react";
import "../styles/product.css"; // Pastikan untuk mengimpor file CSS
import fallbackImage from "../utils/imgCandangan.jpg";

const Product = ({
  kode_barcode,
  kode_barcode2,
  kode_barcode3,
  kode_barcode4,
  nama,
  kategori,
  golongan,
  satuan,
  satuan2,
  satuan3,
  satuan4,
  isi,
  isi2,
  isi3,
  isi4,
  harga_partai,
  harga_partai2,
  harga_partai3,
  harga_partai4,
  toko,
  gambar,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = gambar;
    preloadImage.onload = () => {
      setImageLoaded(true);
    };
  }, [gambar]);

  return (
    <div className="card-produk" onContextMenu={(e) => e.preventDefault()}>
      <div className={`div-produk ${!imageLoaded ? "empty-img" : ""}`}>
        {imageLoaded ? (
          <img
            className="gambar-produk"
            src={gambar}
            alt={nama}
            loading="lazy"
          />
        ) : (
          <img
            className="gambar-produk"
            src={fallbackImage}
            alt={nama}
            loading="lazy"
          />
        )}
      </div>
      <div>
        <p className="title-produk">{nama}</p>
        <p className="harga-produk">Klik Untuk Barcode</p>
      </div>
    </div>
  );
};

export default Product;
