import React, { useContext, useState, useEffect } from "react";
import Barcode from "react-barcode";
import { useParams, useNavigate } from "react-router-dom";
import { ProductContext } from "./ProductContext";
import fallbackImage from "../utils/imgCandangan.jpg";
import { Navbar } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import "../styles/detailProduk.css";

const ProductDetail = () => {
  const { productId } = useParams();
  const { products, setProducts } = useContext(ProductContext);
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();
  const [printBarcodes, setPrintBarcodes] = useState([]);
  const [printSatuan, setPrintSatuan] = useState([]);

  // State untuk jumlah masing-masing barcode
  const [quantity1, setQuantity1] = useState(1);
  const [quantity2, setQuantity2] = useState(1);
  const [quantity3, setQuantity3] = useState(1);
  const [quantity4, setQuantity4] = useState(1);

  useEffect(() => {
    if (products.length === 0) {
      const savedProducts = JSON.parse(localStorage.getItem("products"));
      if (savedProducts) {
        setProducts(savedProducts);
      }
    }
  }, [products, setProducts]);

  useEffect(() => {
    if (products.length > 0) {
      const foundProduct = products
        .flat()
        .find((product) => product.kode_barcode === productId);
      setProduct(foundProduct);
      localStorage.setItem("currentProduct", JSON.stringify(foundProduct));
    } else {
      const savedProduct = JSON.parse(localStorage.getItem("currentProduct"));
      setProduct(savedProduct);
    }
  }, [productId, products]);

  if (!product) {
    return <div>Loading...</div>;
  }

  // Fungsi untuk mengubah jumlah masing-masing barcode
  const handleQuantityChange = (e, barcodeKey) => {
    const value = parseInt(e.target.value, 10);

    switch (barcodeKey) {
      case "kode_barcode":
        setQuantity1(value);
        break;
      case "kode_barcode2":
        setQuantity2(value);
        break;
      case "kode_barcode3":
        setQuantity3(value);
        break;
      case "kode_barcode4":
        setQuantity4(value);
        break;
      default:
        break;
    }
  };

  const handlePrint = (barcodeKey, satuanKey) => {
    let quantity;
    switch (barcodeKey) {
      case "kode_barcode":
        quantity = quantity1;
        break;
      case "kode_barcode2":
        quantity = quantity2;
        break;
      case "kode_barcode3":
        quantity = quantity3;
        break;
      case "kode_barcode4":
        quantity = quantity4;
        break;
      default:
        break;
    }

    const barcodes = [];
    let satuans = "";
    for (let i = 0; i < quantity; i++) {
      barcodes.push(barcodeKey);
      satuans = satuanKey;
    }
    // console.log(barcodeKey);
    setPrintBarcodes(barcodes);
    setPrintSatuan(satuans);

    setTimeout(() => {
      window.print();
      setPrintBarcodes([]);
      setPrintSatuan([]);
    }, 500);
  };

  return (
    <div className="bodyContainer">
      <div className="nav-container">
        <Navbar className="nav-detail">
          <div onClick={() => navigate(-1)} className="button-back">
            <FaArrowLeft />
          </div>
          <Navbar.Brand className="nav-title">{product.nama}</Navbar.Brand>
        </Navbar>
      </div>
      <div className="containerContent">
        <div className="cardContent">
          <div className={`div-image ${!product.gambar ? "empty-img" : ""}`}>
            <img
              className="image-detail"
              src={product.gambar || fallbackImage}
              alt={product.nama}
            />
          </div>
          <div className="containerTitle">
            <div className="titleDetail">{product.nama}</div>
            {/* Barcode 1 */}
            {product.kode_barcode && product.kode_barcode !== "null" && (
              <div className="containerBarcode">
                <div className="satuan">
                  <div>{product.satuan}</div>
                </div>
                <div className="barcode-info">
                  <div className="product-name">{product.nama}</div>
                  <div className="barcode-container">
                    <Barcode value={product.kode_barcode} />
                  </div>
                </div>
                <div className="jumlah">
                  <input
                    type="number"
                    value={quantity1}
                    onChange={(e) => handleQuantityChange(e, "kode_barcode")}
                    min="1"
                    placeholder="Enter number of barcodes"
                  />
                </div>
                <div className="print">
                  <button onClick={() => handlePrint("kode_barcode", "satuan")}>
                    Print
                  </button>
                </div>
              </div>
            )}

            {/* Barcode 2 */}
            {product.kode_barcode2 && product.kode_barcode2 !== "null" && (
              <div className="containerBarcode">
                <div className="satuan">
                  <div>{product.satuan2}</div>
                </div>
                <div className="barcode-info">
                  <div className="product-name">{product.nama}</div>
                  <div className="barcode-container">
                    <Barcode value={product.kode_barcode2} />
                  </div>
                </div>
                <div className="jumlah">
                  <input
                    type="number"
                    value={quantity2}
                    onChange={(e) => handleQuantityChange(e, "kode_barcode2")}
                    min="1"
                    placeholder="Enter number of barcodes"
                  />
                </div>
                <div className="print">
                  <button
                    onClick={() => handlePrint("kode_barcode2", "satuan2")}
                  >
                    Print
                  </button>
                </div>
              </div>
            )}

            {/* Barcode 3 */}
            {product.kode_barcode3 && product.kode_barcode3 !== "null" && (
              <div className="containerBarcode">
                <div className="satuan">
                  <div>{product.satuan3}</div>
                </div>
                <div className="barcode-info">
                  <div className="product-name">{product.nama}</div>
                  <div className="barcode-container">
                    <Barcode value={product.kode_barcode3} />
                  </div>
                </div>
                <div className="jumlah">
                  <input
                    type="number"
                    value={quantity3}
                    onChange={(e) => handleQuantityChange(e, "kode_barcode3")}
                    min="1"
                    placeholder="Enter number of barcodes"
                  />
                </div>
                <div className="print">
                  <button
                    onClick={() => handlePrint("kode_barcode3", "satuan3")}
                  >
                    Print
                  </button>
                </div>
              </div>
            )}

            {/* Barcode 4 */}
            {product.kode_barcode4 && product.kode_barcode4 !== "null" && (
              <div className="containerBarcode">
                <div className="satuan">
                  <div>{product.satuan4}</div>
                </div>
                <div className="barcode-info">
                  <div className="product-name">{product.nama}</div>
                  <div className="barcode-container">
                    <Barcode value={product.kode_barcode4} />
                  </div>
                </div>
                <div className="jumlah">
                  <input
                    type="number"
                    value={quantity4}
                    onChange={(e) =>
                      handleQuantityChange(e, "kode_barcode4", "satuan4")
                    }
                    min="1"
                    placeholder="Enter number of barcodes"
                  />
                </div>
                <div className="print">
                  <button
                    onClick={() =>
                      handlePrint("kode_barcode4", product.satuan4)
                    }
                  >
                    Print
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {printBarcodes.length > 0 && (
        <div className="printContainer">
          {printBarcodes.map((barcodeKey, index) => (
            <div key={index} className="printBarcode">
              <div className="product-name">{product.nama}</div>
              <div className="product-name">({product[printSatuan]})</div>
              <Barcode
                value={product[barcodeKey]}
                width={6}
                height={200}
                displayValue={true}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
