import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/navbar.css";

const NavbarComponent = () => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/search/${query}`);
      setQuery("");
    }
  };
  return (
    <div className="search-navbar">
      <div className="justifyContentCenter">
        <div className="formNavbarSearch">
          <input
            type="text"
            className="searchInput"
            placeholder="cari di mlijo rambi"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
    </div>
  );
};
export default NavbarComponent;
