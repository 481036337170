import React, { useState, useContext, useLayoutEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import NavbarComponent from "../component/NavbarComponent";
// import LoadingAnimation from "./loadingAnimasi";
import Product from "../component/product";
import { ProductContext } from "../component/ProductContext";
// import StickyBottomMenu from "./menuSticky";
import "../styles/search.css";
// import NonProduct from './nonProduct';

const Search = () => {
  const { query } = useParams();
  const { products, setProducts } = useContext(ProductContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noProducts, setNoProducts] = useState(false);
  const words = query.toLowerCase().split(/\s+/);
  const data1 = words[0];
  const data2 = words[1];
  const data3 = words[2];
  const data4 = words[3];
  let BASE_URL = "";
  if (isNaN(query)) {
    BASE_URL = `https://app.mlijorambi.com/apiv2/products?perintah=SELECT kode_barcode,kode_barcode2,kode_barcode3,kode_barcode4,nama,kategori,golongan,satuan,satuan2,satuan3,satuan4,isi,isi2,isi3,isi4,harga_partai,harga_partai2,harga_partai3,harga_partai4,toko,gambar FROM barang WHERE nama REGEXP '${query}|${data1}*.*${data3}|${data1}*.*${data2}|${data2}*.*${data3}|${data3}*.*${data1}|${data3}*.*${data2}|${data2}*.*${data1}|${data1}*.*${data4}|${data4}*.*${data1}|${data2}*.*${data4}|${data4}*.*${data2}|${data3}*.*${data4}|${data4}*.*${data3}' ORDER BY case WHEN nama LIKE '%25${data1} ${data2}%25' THEN 1 WHEN nama LIKE '%25${data2} ${data3}%25' THEN 2 WHEN nama LIKE '%25${data1} ${data3}%25' THEN 3 WHEN nama LIKE '%25${data1}%25' THEN 4 WHEN nama LIKE '%25${data2}%25' THEN 5 WHEN nama LIKE '%25${data3}%25' THEN 6 ELSE 7 END`;
  } else {
    BASE_URL = `https://app.mlijorambi.com/apiv2/products?perintah=SELECT kode_barcode,kode_barcode2,kode_barcode3,kode_barcode4,nama,kategori,golongan,satuan,satuan2,satuan3,satuan4,isi,isi2,isi3,isi4,harga_partai,harga_partai2,harga_partai3,harga_partai4,toko,gambar FROM barang WHERE kode_barcode = ${query} OR kode_barcode2 = ${query} OR kode_barcode3 = ${query} OR kode_barcode4 = ${query}`;
  }

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const respon = await axios.get(BASE_URL);
        const allProducts = Array.isArray(respon.data) ? respon.data : [];
        setProducts(allProducts);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [query, setProducts, BASE_URL]);

  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="searchDetail">
      <div className="navbarSearch">
        <NavbarComponent />
      </div>
      <div className="judulSearch">
        <span className="judulSearch">Barang yang dicari: {query}</span>
      </div>

      <div className="searchContainer">
        {products.map((product, index) => (
          <div options={{ preventScrollReset: true }} key={index}>
            <Link
              rel="prefetch"
              className="item-link-home"
              to={`/product/${product.kode_barcode}`}
              key={product.kode_barcode}
              style={{ textDecoration: "none" }}
            >
              <Product {...product} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Search;
