import React, { useState, useEffect } from "react";
import { setInstallStatus, getInstallStatus } from "../indexedDB";
import "../styles/PopupInstallButton.css";
import LoadingAnimation from "./loadingAnimasi";

function InstallPage() {
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(false);
  const [isInstallSuccessPopupVisible, setIsInstallSuccessPopupVisible] =
    useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallButtonVisible, setIsInstallButtonVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isServiceWorkerActive, setIsServiceWorkerActive] = useState(false);

  useEffect(() => {
    // Mengecek status instalasi saat komponen dimuat
    getInstallStatus().then((isInstalled) => {
      if (!isInstalled) {
        setIsInstallButtonVisible(true);
      } else {
        setIsInstallButtonVisible(false);
      }
    });

    // Mengecek apakah service worker aktif
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        setIsServiceWorkerActive(!!registration);
      });
    }

    // Menambahkan event listener untuk 'beforeinstallprompt'
    const handleBeforeInstallPrompt = (e) => {
      // Mencegah prompt instalasi default
      e.preventDefault();
      // Menyimpan event untuk digunakan nanti
      setDeferredPrompt(e);
      // Menampilkan tombol install jika service worker aktif
      setIsInstallButtonVisible(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Membersihkan event listener saat komponen dilepas
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        setIsProgressBarVisible(true);
        setProgress(0);
        const interval = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress >= 100) {
              clearInterval(interval);
              setIsProgressBarVisible(false);
              return 100;
            }
            return prevProgress + 10;
          });
        }, 500);

        setTimeout(async () => {
          // Mengirim pesan ke service worker untuk menghapus data
          navigator.serviceWorker.controller.postMessage({
            type: "deleteData",
          });
          await setInstallStatus(true);
          setIsInstallSuccessPopupVisible(true);
        }, 3000);
      } else {
        await setInstallStatus(false);
        alert("Anda belum menginstal aplikasi");
      }
      setDeferredPrompt(null);
      setIsInstallButtonVisible(false);
    }
  };

  const handleClose = () => {
    setIsInstallSuccessPopupVisible(false);
  };

  return (
    <div className="containerInstallPage">
      {isInstallButtonVisible && isServiceWorkerActive && (
        <div className="popup-install">
          <h1>Mlijo Barcode</h1>
          <h2>Aplikasi cetak barcode Mlijo Rambi</h2>
          <button onClick={handleInstallClick} className="install-button">
            Install
          </button>
        </div>
      )}
      {isProgressBarVisible && (
        <div>
          <LoadingAnimation />
        </div>
      )}
      {isInstallSuccessPopupVisible && (
        <div className="popup-success">
          <p>
            MLIJO Barcode berhasil terpasang. Klik tutup dan cari aplikasi di
            perangkat Anda. Biasanya ada paling belakang.
          </p>
          <br />
          <button onClick={handleClose} className="button-link">
            Tutup
          </button>
        </div>
      )}
    </div>
  );
}

export default InstallPage;
