import { openDB } from "idb";

const DB_NAME = "barcode-install-status";
const DB_VERSION = 1;
const STORE_NAME = "status";

async function initDB() {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: "id" });
      }
    },
  });
  return db;
}

export async function setInstallStatus(status) {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  const store = tx.objectStore(STORE_NAME);
  await store.put({ id: "installed", value: status });
  await tx.done;
}

export async function getInstallStatus() {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, "readonly");
  const store = tx.objectStore(STORE_NAME);
  const status = await store.get("installed");
  await tx.done;
  return status ? status.value : false;
}
