import React, { useState, useContext, useLayoutEffect } from "react";
import NavbarComponent from "../component/NavbarComponent";
// import StickyBottomMenu from "./menuSticky";
import "../styles/search.css";
// import NonProduct from './nonProduct';

const Home = () => {
  return (
    <div className="searchDetail">
      <div className="navbarSearch">
        <NavbarComponent />
      </div>
    </div>
  );
};

export default Home;
