import React from "react";

// Definisikan komponen animasi loading
function LoadingAnimation() {
  return (
    <div className="loading-animation">
      <div className="spinner"></div>
    </div>
  );
}

export default LoadingAnimation;
