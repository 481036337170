import React from "react";
import Home from "../src/Pages/home";
import Search from "./Pages/search";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { ProductProvider } from "./component/ProductContext";
import ProductDetail from "./component/detailProduk";
import InstallPage from "./component/InstallPage";

function App() {
  return (
    <Router>
      <ProductProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/install" element={<InstallPage />} />
          <Route path="/search/:query" element={<Search />} />
          <Route path="/product/:productId" element={<ProductDetail />} />
        </Routes>
      </ProductProvider>
    </Router>
  );
}

export default App;
